import * as styles from './features.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

import Button from '../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import createImage from '../images/landing-pages/create.gif';
import designImage from '../images/landing-pages/design.gif';
import { graphql } from 'gatsby';

const FeaturesPage = ({ data }) => {
  const [features, setFeatures] = useState({ create: 'ai', design: 'colors', collect: 'form', share: 'embed', analytics: 'funnel' });
  const featureImages = data.featureImages.edges;
  
  return (
    <>
      <SEO
        title="Features"
        description="Explore the comprehensive features of Interact's quiz-building platform. From AI-powered quiz creation to advanced analytics, Interact offers everything you need to engage your audience and drive conversions."
      />
      <Header btnTheme="primary" />
      <main className={ styles.features }>
        <Section theme="white" style={ { marginTop: `4.5rem` } }>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Features</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground text-center">Build and promote the perfect quiz for your business. Generate leads, segment your audience, and recommend products seamlessly.</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className={ styles.frame }>
                <GatsbyImage image={ getImage(featureImages[12].node) } alt="Quiz templates examples"/>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white" style={{ padding: '2rem 0 5rem 0' }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-black">Quizzes are better than lead magnets.</p>
            </div>
            <div className="col-lg-6 col-xl-6 text-center">
              <p className="heading-sm color-black">Most lead magnets are uninspiring and lack engagement. In contrast, quizzes are both enjoyable and interactive.</p>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '2rem' }}>
            <div className="col-12 col-xl-10">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-6">
                  <p className="heading-sm color-black text-center"><strong>Quizzes</strong></p>
                </div>
                <div className="col-12 col-lg-6 d-none d-lg-block">
                  <p className="heading-sm color-black text-center"><strong>Other lead magnets</strong></p>
                </div>
              </div>
              <div className="row justify-content-center no-gutters" style={ { borderTop: `1px solid rgb(229 231 235)`  } }>
                <div className="col-12 col-lg-6">
                  <div className={ styles.tableItem }>
                    <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                    <p className="text-body color-black">Interact simplifies quiz creation, allowing you to infuse them with your unique personality and brand voice.</p>
                  </div>
                  <div className={ styles.tableItem }>
                    <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                    <p className="text-body color-black">Utilize your quiz as part of your daily social media content, leveraging the insights gained to personalize your marketing strategies.</p>
                  </div>
                  <div className={ styles.tableItem }>
                    <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                    <p className="text-body color-black">Enhance shareability and foster genuine engagement, creating an experience akin to conversing with a real person.</p>
                  </div>
                  <div className={ styles.tableItem }>
                    <div className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></div>
                    <p className="text-body color-black">Effectively nurture your audience while expanding your email list. Additionally, your quiz establishes instant familiarity, likability, and trust, making new leads eager to invest in your offerings.</p>
                  </div>
                </div>
                <div className="col-12 d-lg-none"  style={ { borderBottom: `1px solid rgb(229 231 235)`  } }>
                  <p className="heading-sm color-black text-center"><strong>Other lead magnets</strong></p>
                </div>
                <div className="col-12 col-lg-6">
                  <div className={ styles.tableItem }>
                    <div className={ styles.times }><FontAwesomeIcon icon={ faTimes }/></div>
                    <p className="text-body color-muted-foreground">Search Engine Optimization is inherently complex, often yielding less-than-satisfactory results.</p>
                  </div>
                  <div className={ styles.tableItem }>
                    <div className={ styles.times }><FontAwesomeIcon icon={ faTimes }/></div>
                    <p className="text-body color-muted-foreground">To gain traction on social media, consistent daily posting is necessary, yet it rarely provides meaningful insights about your audience.</p>
                  </div>
                  <div className={ styles.tableItem }>
                    <div className={ styles.times }><FontAwesomeIcon icon={ faTimes }/></div>
                    <p className="text-body color-muted-foreground">Organic reach has significantly declined. To ensure visibility for your social posts, substantial financial investment is required.</p>
                  </div>
                  <div className={ styles.tableItem }>
                    <div className={ styles.times }><FontAwesomeIcon icon={ faTimes }/></div>
                    <p className="text-body color-muted-foreground">Furthermore, even when individuals download your free offerings, most eBooks and PDFs remain unused on subscribers' devices, failing to convert them into enthusiastic supporters eager for more of your content.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row">
            <div className="col-lg-6 col-xl-5">
              <p className="heading-xs-upper color-pink" style={{ fontSize: '14px' }}><strong>Step 1</strong></p>
              <p className="heading-xl color-black">Create</p>
              <p className="heading-sm color-muted-foreground">Tailor your quiz with advanced customization options to seamlessly align with your brand identity and messaging.</p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-6 col-xl-5">
              <div className={ styles.featureBtns }>
                <button className={ [styles.featureBtn, features.create === 'ai' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, create: 'ai' })}>
                  <p className="heading-sm color-black"><strong>AI-powered quiz maker</strong></p>
                  <p className="text-body color-muted-foreground">Our AI quiz maker allows you to create a quiz in minutes without any design or coding skills.</p>
                </button>
                <button className={ [styles.featureBtn, features.create === 'logic' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, create: 'logic' })}>
                  <p className="heading-sm color-black"><strong>Conditional logic</strong></p>
                  <p className="text-body color-muted-foreground">Add conditional logic to your quiz to create a personalized experience for your audience.</p>
                </button>
                <button className={ [styles.featureBtn, features.create === 'redirect' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, create: 'redirect' })}>
                  <p className="heading-sm color-black"><strong>Quiz results redirect</strong></p>
                  <p className="text-body color-muted-foreground">Redirect your quiz results to your own website or custom landing page.</p>
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              {features.create === 'ai' && (
                <div className={styles.frame}>
                  <img src={createImage} className={styles.designImage} alt="AI quiz maker" />
                </div>
              )}
              {features.create === 'logic' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[13].node) } alt="Conditional logic"/>
                </div>
              )}
              {features.create === 'redirect' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[14].node) } alt="Quiz results redirect"/>
                </div>
              )}
            </div>
          </div>
        </Section>
        <Section theme="white">
          <div className="row">
            <div className="col-lg-6 col-xl-5">
              <p className="heading-xs-upper color-pink" style={{ fontSize: '14px' }}><strong>Step 2</strong></p>
              <p className="heading-xl color-black">Design</p>
              <p className="heading-sm color-muted-foreground">Customize your quiz with our full-featured quiz builder to match your brand's look and feel.</p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-6 col-xl-5">
              <div className={ styles.featureBtns }>
                <button className={ [styles.featureBtn, features.design === 'colors' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, design: 'colors' })}>
                  <p className="heading-sm color-black"><strong>Colors & font styles</strong></p>
                  <p className="text-body color-muted-foreground">Edit your quiz with color schemes and font options to align with your brand guide.</p>
                </button>
                <button className={ [styles.featureBtn, features.design === 'images' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, design: 'images' })}>
                  <p className="heading-sm color-black"><strong>Beautiful stock images</strong></p>
                  <p className="text-body color-muted-foreground">Access a vast library of high-quality images from Unsplash and Giphy to enhance your quiz's visual appeal and engagement.</p>
                </button>
                <button className={ [styles.featureBtn, features.design === 'cta' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, design: 'cta' })}>
                  <p className="heading-sm color-black"><strong>Custom CTAs</strong></p>
                  <p className="text-body color-muted-foreground">Craft custom CTAs for your quiz to drive engagement and conversions.</p>
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              {features.design === 'colors' && (
                <div className={styles.frame}>
                  <img src={designImage} className={styles.designImage} alt="Design theme examples" />
                </div>
              )}
              {features.design === 'images' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[18].node) } alt="Unsplash and Giphy logos"/>
                </div>
              )}
              {features.design === 'cta' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[15].node) } alt="Custom CTA example with color picker"/>
                </div>
              )}
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row">
            <div className="col-lg-6 col-xl-5">
              <p className="heading-xs-upper color-pink" style={{ fontSize: '14px' }}><strong>Step 3</strong></p>
              <p className="heading-xl color-black">Collect Leads</p>
              <p className="heading-sm color-muted-foreground">Expand your email list using our powerful lead generation tools.</p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-6 col-xl-5">
              <div className={ styles.featureBtns }>
                <button className={ [styles.featureBtn, features.collect === 'form' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, collect: 'form' })}>
                  <p className="heading-sm color-black"><strong>Opt-in form</strong></p>
                  <p className="text-body color-muted-foreground">Create customizable opt-in forms seamlessly integrated into your quiz to efficiently capture and convert high-quality leads.</p>
                </button>
                <button className={ [styles.featureBtn, features.collect === 'segment' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, collect: 'segment' })}>
                  <p className="heading-sm color-black"><strong>Segment leads</strong></p>
                  <p className="text-body color-muted-foreground">Segment your leads into different lists based on their quiz results to tailor your messaging and offers.</p>
                </button>
                <button className={ [styles.featureBtn, features.collect === 'redirect' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, collect: 'redirect' })}>
                  <p className="heading-sm color-black"><strong>Custom integrations</strong></p>
                  <p className="text-body color-muted-foreground">Connect your quiz to your favorite marketing tools and platforms to streamline your lead generation process.</p>
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              {features.collect === 'form' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[16].node) } alt="Opt-in form example"/>
                </div>
              )}
              {features.collect === 'segment' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[17].node) } alt="Mailchimp integration setup"/>
                </div>
              )}
              {features.collect === 'redirect' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[6].node) } alt="Custom integrations"/>
                </div>
              )}
            </div>
          </div>
        </Section>
        <Section theme="white">
          <div className="row">
            <div className="col-lg-6 col-xl-5">
              <p className="heading-xs-upper color-pink" style={{ fontSize: '14px' }}><strong>Step 4</strong></p>
              <p className="heading-xl color-black">Share & Embed</p>
              <p className="heading-sm color-muted-foreground">Share your quiz with your audience and embed it directly on your website.</p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-6 col-xl-5">
              <div className={ styles.featureBtns }>
                <button className={ [styles.featureBtn, features.share === 'embed' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, share: 'embed' })}>
                  <p className="heading-sm color-black"><strong>Effortless embed</strong></p>
                  <p className="text-body color-muted-foreground">Copy and paste a variety of embed codes or use one of our embed providers.</p>
                </button>
                <button className={ [styles.featureBtn, features.share === 'promotions' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, share: 'promotions' })}>
                  <p className="heading-sm color-black"><strong>Promotional tools</strong></p>
                  <p className="text-body color-muted-foreground">Use our promotional tools to share your quiz as an announcement bar or popup.</p>
                </button>
                <button className={ [styles.featureBtn, features.share === 'mobile' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, share: 'mobile' })}>
                  <p className="heading-sm color-black"><strong>Mobile friendly</strong></p>
                  <p className="text-body color-muted-foreground">Your quiz will look great on any device, with specific mobile-first features.</p>
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              {features.share === 'embed' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[19].node) } alt="Embed code example"/>
                </div>
              )}
              {features.share === 'promotions' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[20].node) } alt="Quiz popup embed example"/>
                </div>
              )}
              {features.share === 'mobile' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[21].node) } alt="Quiz results redirect"/>
                </div>
              )}
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row">
            <div className="col-lg-6 col-xl-5">
              <p className="heading-xs-upper color-pink" style={{ fontSize: '14px' }}><strong>Step 5</strong></p>
              <p className="heading-xl color-black">Analyze</p>
              <p className="heading-sm color-muted-foreground">See how your quiz is performing and make data-driven decisions to improve your results.</p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-6 col-xl-5">
              <div className={ styles.featureBtns }>
                <button className={ [styles.featureBtn, features.analytics === 'funnel' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, analytics: 'funnel' })}>
                  <p className="heading-sm color-black"><strong>Funnel analytics</strong></p>
                  <p className="text-body color-muted-foreground">See how your quiz is performing and make data-driven decisions to improve your results.</p>
                </button>
                <button className={ [styles.featureBtn, features.analytics === 'answers' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, analytics: 'answers' })}>
                  <p className="heading-sm color-black"><strong>Data insights</strong></p>
                  <p className="text-body color-muted-foreground">Gain comprehensive insights into your audience's responses with detailed question-by-question analytics for your quizzes.</p>
                </button>
                <button className={ [styles.featureBtn, features.analytics === 'results' ? styles.active : ''].join(' ') } onClick={() => setFeatures({ ...features, analytics: 'results' })}>
                  <p className="heading-sm color-black"><strong>Individual lead details</strong></p>
                  <p className="text-body color-muted-foreground">Access detailed analytics on individual responses, including answer breakdowns and final results for each participant.</p>
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              {features.analytics === 'funnel' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[8].node) } alt="Embed code example"/>
                </div>
              )}
              {features.analytics === 'answers' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[22].node) } alt="Question answers analytics"/>
                </div>
              )}
              {features.analytics === 'results' && (
                <div className={styles.frame}>
                  <GatsbyImage image={ getImage(featureImages[23].node) } alt="Quiz results redirect"/>
                </div>
              )}
            </div>
          </div>
        </Section>
        <Section theme="default">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-black">Support when you need it.</p>
            </div>
          </div>
          <div className="row justify-content-center align-items-stretch" style={{ marginTop: '3rem' }}>
            <div className="col-lg-6">
              <div className={ styles.support }>
                <p className="heading-xs-upper">Talk</p>
                <p className="heading-lg">Live chat & email</p>
                <p className="heading-sm">Our support team is on standby:<br/>Mon-Fri 9 am - 4:30 pm PST</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={ styles.support }>
                <p className="heading-xs-upper">Explore</p>
                <p className="heading-lg">Knowledge base</p>
                <p className="heading-sm">Find answers to common questions and get the help you need quickly.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-stretch" style={{ marginTop: '1.5rem' }}>
            <div className="col-lg-6">
              <div className={ styles.support }>
                <p className="heading-xs-upper">Read</p>
                <p className="heading-lg">Interact Blog</p>
                <p className="heading-sm">Read the latest news and tips on how to use Interact.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={ styles.support }>
                <p className="heading-xs-upper">Listen</p>
                <p className="heading-lg">Grow Podcast</p>
                <p className="heading-sm">Listen to interviews with industry experts and learn how to grow your business with Interact.</p>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

export default FeaturesPage;

export const query = graphql`
  query {
    featureImages: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativePath: {regex: "/features/features-/"}}, sort: {fields: relativePath}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData (
              layout: CONSTRAINED,
              width: 1200,
              quality: 100,
            )
          }
        }
      }
    },
  }
`